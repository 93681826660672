import React from "react"
import { ResourceFlair, resources } from "~config/constants/resources"
import Integrate from "~images/layout/header/resources/integrate.svg"
import Features from "~images/layout/header/resources/features.svg"

export type Resource = {
    label: string
    href: string
    description: string
    flair: React.ReactNode
}

export const mobileLinks: Resource[] = [
    {
        label: "Features",
        href: "/features",
        description: "Explore the tools Chckvet can offer your practice.",
        flair: <ResourceFlair src={Features} alt="Features" />,
    },
    ...resources,
    {
        label: "PIMS We Work With",
        href: "/pims",
        description: "Explore how Chckvet works with your system.",
        flair: <ResourceFlair src={Integrate} alt="PIMS" />,
    },
]
