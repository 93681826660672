import React from "react"
import { Link } from "gatsby"
import { Image } from "@chakra-ui/image"
import { Box } from "@chakra-ui/layout"

import Green from "~images/logo/png/default-w-chckup.png"
import WhiteVet from "~images/logo/png/white-w-chckup.png"
import DarkVet from "~images/logo/png/dark-w-chckup.png"

interface Props {
    useColoredLogo?: boolean
    useChckvet?: boolean
}

export default function HeaderLogo({ useColoredLogo, useChckvet }: Props) {
    return (
        <Link to={"/"}>
            <Box>
                <Image
                    src={
                        useChckvet
                            ? useColoredLogo
                                ? DarkVet
                                : WhiteVet
                            : useColoredLogo
                            ? Green
                            : WhiteVet
                    }
                    alt="Chckvet Logo"
                    width={102}
                />
            </Box>
        </Link>
    )
}
