import React from "react"
import { useLocation } from "@reach/router"
import { Box, Flex, Center, HStack } from "@chakra-ui/layout"

import HeaderLogo from "./logo"
import HeaderWrapper from "./wrapper"
import LogInButton from "./links/login"
import MobileMenuButton from "./mobile"
import PIMSButton from "./links/pims-button"
import FeaturesButton from "./links/features"
import ResourcesButton from "./links/resources"
import ResponsiveBlock from "../responsive-block"
import BookADemoButton from "~components/shared/book-a-demo-button"

export default function Header() {
    const { pathname } = useLocation()
    const isHomepage = pathname === "/"

    return (
        <HeaderWrapper isHomepage={isHomepage}>
            <ResponsiveBlock isWide py={2}>
                <Flex
                    flexDir="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box flex={1}>
                        <Box w="fit-content">
                            <HeaderLogo useColoredLogo={!isHomepage} />
                        </Box>
                    </Box>
                    <Center
                        flex={2}
                        gridGap={4}
                        display={{ base: "none", lg: "flex" }}
                    >
                        <FeaturesButton useColoredText={!isHomepage} />
                        <ResourcesButton useColoredText={!isHomepage} />
                        <PIMSButton useColoredText={!isHomepage} />
                    </Center>
                    <HStack
                        spacing={6}
                        flex={1}
                        justifyContent="flex-end"
                        display={{ base: "none", lg: "flex" }}
                        pos="relative"
                    >
                        <LogInButton useColoredText={!isHomepage} />
                        <BookADemoButton
                            size="md"
                            colorScheme="pink"
                            pos="relative"
                        />
                    </HStack>
                    <MobileMenuButton />
                </Flex>
            </ResponsiveBlock>
        </HeaderWrapper>
    )
}
