import React from "react"
import { Icon } from "@chakra-ui/icon"
import { IconType } from "react-icons"
import { FaPaw, FaStethoscope } from "react-icons/fa"

import LinkMenu from "./menu"
import MenuOption, { NonMenuOption } from "./menu/option"
import GradientDivider from "~components/shared/gradient-divider"

interface Props {
    useColoredText?: boolean
}

export default function LogInButton({ useColoredText }: Props) {
    return (
        <LinkMenu
            label="Log In"
            placement="bottom-end"
            useColoredText={useColoredText}
            listOverrides={{ px: 0, py: 0 }}
        >
            <LoginOptions usingMenu />
        </LinkMenu>
    )
}

export function LoginOptions({ usingMenu }: { usingMenu?: boolean }) {
    if (usingMenu) {
        return (
            <>
                <MenuOption
                    flair={<MenuIcon icon={FaPaw} color="purple" />}
                    href={process.env.GATSBY_PORTAL_URL || ""}
                    description="Chckpet Portal"
                    isExternal
                >
                    Pet parents
                </MenuOption>
                <GradientDivider />
                <MenuOption
                    flair={<MenuIcon icon={FaStethoscope} color="brand" />}
                    href={process.env.GATSBY_DASHBOARD_URL || ""}
                    description="Chckvet Clinic Dashboard"
                    isExternal
                >
                    Clinic team member
                </MenuOption>
            </>
        )
    }

    return (
        <>
            <NonMenuOption
                flair={<MenuIcon icon={FaPaw} color="purple" />}
                href={process.env.GATSBY_PORTAL_URL || ""}
                description="Chckpet Portal"
            >
                Pet parents
            </NonMenuOption>
            <NonMenuOption
                flair={<MenuIcon icon={FaStethoscope} color="brand" />}
                href={process.env.GATSBY_DASHBOARD_URL || ""}
                description="Chckvet Clinic Dashboard"
            >
                Clinic team member
            </NonMenuOption>
        </>
    )
}

function MenuIcon({ icon, color }: { icon: IconType; color?: string }) {
    return <Icon as={icon} fontSize="2xl" color={`${color || "gray"}.500`} />
}
